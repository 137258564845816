/*
|-----------------------------------------------------------------------------
| Authentication Types
|-----------------------------------------------------------------------------
*/

export const AUTH_REGISTER = 'AUTH_REGISTER';
export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_COMPLETE = 'AUTH_COMPLETE';
export const AUTH_CONFIRM = 'AUTH_CONFIRM';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const PROFILE = 'PROFILE';
export const VERIFY = 'VERIFY';

/*
|-----------------------------------------------------------------------------
| Setup Types
|-----------------------------------------------------------------------------
*/

export const SETUP_BUSINESS = 'SETUP_BUSINESS';
export const SETUP_CUSTOMER = 'SETUP_CUSTOMER';
export const SETUP_EMAIL = 'SETUP_EMAIL';

/*
|-----------------------------------------------------------------------------
| Data Types
|-----------------------------------------------------------------------------
*/

export const LEARN = 'LEARN';
export const UPDATE_OPTION_STATE = 'UPDATE_OPTION_STATE';
export const RESET_LEARN_STATE = 'RESET_LEARN_STATE';
export const ACTIVITY = 'ACTIVITY';
export const REGION = 'REGION';
export const CITY = 'CITY';

/*
|-----------------------------------------------------------------------------
| Error Types
|-----------------------------------------------------------------------------
*/

export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const ERR_SERVER = 'ERR_SERVER';
export const ERR_HTTP_NOT_FOUND = 'ERR_HTTP_NOT_FOUND';
export const ERR_HTTP_FORBIDDEN = 'ERR_HTTP_FORBIDDEN';
export const ERR_HTTP_UNAUTHORIZED = 'ERR_HTTP_UNAUTHORIZED';
export const ERR_HTTP_BAD_REQUEST = 'ERR_HTTP_BAD_REQUEST';
export const ERR_HTTP_UNPROCESSABLE_ENTITY = 'ERR_HTTP_UNPROCESSABLE_ENTITY';
