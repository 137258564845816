import React from 'react';
import Grid from '@mui/material/Grid';
import Input from '@mui/material/Input';
import SendSharpIcon from '@mui/icons-material/SendSharp';
import Button from '@mui/material/Button';
import { toast } from 'react-toastify';

class ChatInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = { chatInput: '' };

    // React ES6 does not bind 'this' to event handlers by default
    this.submitHandler = this.submitHandler.bind(this);
    this.textChangeHandler = this.textChangeHandler.bind(this);
  }

  submitHandler(event) {
    // Stop the form from refreshing the page on submit
    event.preventDefault();

    // Clear the input box
    this.setState({ chatInput: '' });

    // Call the onSend callback with the chatInput message
    this.props.onSend(this.state.chatInput);
  }

  textChangeHandler(event) {
    this.setState({ chatInput: event.target.value });
  }

  render() {
    return (
      //   <form className="chat-input" onSubmit={this.submitHandler}>
      //     <input
      //       type="text"
      //       onChange={this.textChangeHandler}
      //       value={this.state.chatInput}
      //       placeholder="Write a message..."
      //       required
      //     />
      //   </form>
      <Grid container xs={12} style={{ borderTop: '1px solid #003671' }}>
        <Grid item xs={10} alignItems="center">
          <form
            onSubmit={this.submitHandler}
            style={{ width: '100%' }}
            id="chatform"
          >
            <Input
              placeholder="Write a message..."
              id="chatinput"
              style={{ width: '100%', height: '48px', padding: '12px' }}
              disableUnderline
              type="text"
              value={this.state.chatInput}
              onChange={this.textChangeHandler}
              inputProps={{ 'aria-label': 'description' }}
            />
          </form>
        </Grid>
        <Grid
          container
          xs={2}
          justifyContent="center"
          style={{ cursor: 'pointer', paddingLeft: '5px' }}
          alignItems="center"
          onClick={this.submitHandler}
        >
          <SendSharpIcon color="primary" />
        </Grid>
      </Grid>
    );
  }
}

ChatInput.defaultProps = {};

export default ChatInput;
