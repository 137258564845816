/* eslint-disable react/function-component-definition */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from 'react';
import {Helmet} from 'react-helmet-async';

export default function HelmetMaker(allprops) {
  const props = allprops && typeof allprops === 'object' ? allprops.props || allprops : {};
  return (
    <Helmet>
      <title>{props.title || 'Find Me Sales'}</title>
      <meta
        name="description"
        content={
          props.description ||
          'Make sales to over 200m+ businesses worldwide with our real-time database, AI-led research, and automation to create easy sales actions.'
        }
      />
      <meta
        name="keywords"
        content={
          props.keywords ||
          'Leads, Sales, Lead generation, artificial intelligence, sales solution, AI, Automation, Sales Funnel, Sales AI'
        }
      />
      <meta name="author" content="Find Me Sales" />
      <meta property="og:locale" content="en_GB" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={props.title || 'Find Me Sales'} />
      <meta
        name="og:description"
        content={
          props.description ||
          'Make sales to over 200m+ businesses worldwide with our real-time database, AI-led research, and automation to create easy sales actions.'
        }
      />
      <meta property="og:url" content={props.url || 'https://findmesales.com'} />
      <meta property="og:site_name" content="Find Me Sales" />
      <meta
        property="og:image"
        content={
          props.image ||
          'https://storage.googleapis.com/find-me-sales-bucket/front-end/homepage/Homepage.jpg'
        }
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@FindMeSalesHQ" />
      <meta name="twitter:title" content={props.title || 'Find Me Sales'} />
      <meta
        name="twitter:description"
        content={
          props.description ||
          'Make sales to over 200m+ businesses worldwide with our real-time database, AI-led research, and automation to create easy sales actions.'
        }
      />
      <meta
        name="twitter:image"
        content={
          props.image ||
          'https://storage.googleapis.com/find-me-sales-bucket/front-end/homepage/Homepage.jpg'
        }
      />
    </Helmet>
  );
}
