import {PROFILE} from 'app/constants/actionTypes';

const initState = {
  Profile: {
    firstName: null,
    lastName: null,
    businessName: null,
    jobTitle: null,
    email: null,
    phone: null,
    agreeTerms: null,
    password: null,
    updatedAt: null,
    subscription: null,
    active_profiles: [],
    notifications: [],
  },
};

export function ProfileReducer(state = initState, {type, payload}) {
  switch (type) {
    case PROFILE:
      return {
        ...state,
        Profile: {
          ...payload.response.data,
        },
      };
    default:
      return state;
  }
}
