/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import { Route, Navigate, Routes, Outlet, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { PROFILE_ACTION } from 'app/actions/profileAction';
import PropTypes from 'prop-types';

/**
 * Protect private routes for unauthorized access.
 *
 * @export
 * @function
 * @name PrivateRoute
 * @returns {Function}
 */
const PrivateRoute = function ({ children, page }) {
  const dispatch = useDispatch();
  const location = useLocation();
  // const navigate = useNavigate();
  const UserProfile = () => {
    const user = useSelector((state) => state.PROFILE_REDUCER.Profile);
    return user;
  };
  const Auth = () => {
    const user = useSelector((state) => state.AUTH_REDUCER.Auth);
    return user;
  };

  const profile = UserProfile();
  const auth = Auth();
  const { isAuthenticated } = auth;

  const path = `${location.pathname}${location.search}`;

  useEffect(() => {
    if (auth.token !== null) {
      const DISPATCH_PROFILE = () => {
        dispatch(PROFILE_ACTION(auth.token));
      };
      DISPATCH_PROFILE();
    }
  }, []);
  return isAuthenticated ? (
    children
  ) : (
    <Navigate to="/login" state={{ lastpage: path }} replace />
  );
  // if (auth) {
  //   return children;
  // } else {
  //   return <Navigate to="/login" />;
  // }
};
// }
// function PrivateRoute({component: Component, ...rest}) {
//   return (
//     <Routes>
//     <Route
//       {...rest}
//       render={props =>
//         Cookies.get('_token') !== null ? (
//           <Component {...props} />
//         ) : (
//           <Navigate to="/login" />
//         )
//       }
//     />
//     </Routes>
//   );
// }

// PrivateRoute.propTypes = {
//   component: PropTypes.any.isRequired,
//   history: PropTypes.object.isRequired,
// };

export default PrivateRoute;
