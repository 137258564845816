import {
  AUTH_LOGIN_URL,
  AUTH_REGISTER_URL,
  FORGOT_PASSWORD_URL,
  RESET_PASSWORD_URL,
  EMAIL_VERIFY_URL,
  EMAIL_CONFIRM_URL,
  CHANGE_PASSWORD_URL,
} from 'app/constants/url';
import {customFetch} from 'app/utils/customFetch';

/**
 * Send login request to the application.
 *
 * @export
 * @function
 * @name HTTP_REQUEST_LOGIN
 * @param payload
 * @returns {Promise<Response>}
 */
export async function HTTP_REQUEST_LOGIN(payload) {
  return await customFetch(AUTH_LOGIN_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });
}

/**
 * Send register request to the application.
 *
 * @export
 * @function
 * @name HTTP_REQUEST_REGISTER
 * @param payload
 * @returns {Promise<Response>}
 */
export async function HTTP_REQUEST_REGISTER(payload) {
  return await customFetch(AUTH_REGISTER_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });
}

/**
 * Send forgot password request to the application.
 *
 * @export
 * @function
 * @name HTTP_REQUEST_FORGOT_PASSWORD
 * @param payload
 * @returns {Promise<Response>}
 */
export async function HTTP_REQUEST_FORGOT_PASSWORD(payload) {
  return await customFetch(FORGOT_PASSWORD_URL, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });
}

/**
 * Send email validation request to the application.
 *
 * @export
 * @function
 * @name HTTP_REQUEST_EMAIL_VERIFY
 * @param payload
 * @returns {Promise<Response>}
 */
export async function HTTP_REQUEST_EMAIL_VERIFY(payload) {
  return await customFetch(EMAIL_VERIFY_URL, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });
}

/**
 * Send email confirmation request to the application.
 *
 * @export
 * @function
 * @name HTTP_REQUEST_EMAIL_CONFIRM
 * @param payload
 * @returns {Promise<Response>}
 */
export async function HTTP_REQUEST_EMAIL_CONFIRM(payload) {
  return await customFetch(EMAIL_CONFIRM_URL, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });
}

/**
 * Send reset password request to the application.
 *
 * @export
 * @function
 * @name HTTP_REQUEST_RESET_PASSWORD
 * @param payload
 * @returns {Promise<Response>}
 */
export async function HTTP_REQUEST_RESET_PASSWORD(payload) {
  return await customFetch(RESET_PASSWORD_URL, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });
}

/**
 * Send change password request to the application.
 *
 * @export
 * @function
 * @name HTTP_REQUEST_CHANGE_PASSWORD
 * @param payload
 * @returns {Promise<Response>}
 */
export async function HTTP_REQUEST_CHANGE_PASSWORD(payload) {
  return await customFetch(CHANGE_PASSWORD_URL, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });
}
