import {ACTIVITY_URL, REGION_URL, CITY_URL, UKSIC_URL, PRICING_URL} from 'app/constants/url';
import {customFetch} from 'app/utils/customFetch';

/**
 * Fetch business activities.
 *
 * @export
 * @function
 * @name HTTP_REQUEST_ACTIVITY
 * @param payload
 * @returns {<Response>}
 */
export async function HTTP_REQUEST_ACTIVITY(payload) {
  return await customFetch(ACTIVITY_URL, {
    method: 'GET',
  });
}

/**
 * Fetch business activities.
 *
 * @export
 * @function
 * @name HTTP_REQUEST_UKSIC
 * @param payload
 * @returns {<Response>}
 */
export async function HTTP_REQUEST_UKSIC(payload) {
  return await customFetch(UKSIC_URL, {
    method: 'GET',
  });
}

/**
 * Fetch business activities.
 *
 * @export
 * @function
 * @name HTTP_REQUEST_PRICING
 * @param payload
 * @returns {<Response>}
 */
export async function HTTP_REQUEST_PRICING(payload, currency, pricing) {
  // console.log('HTTP_REQUEST_PRICING', payload, currency, pricing);
  let urlWithPayload = PRICING_URL;

  const queryParams = new URLSearchParams();

  if (payload) {
    queryParams.append('p', payload);
  }

  if (currency) {
    queryParams.append('c', currency.toUpperCase());
  }

  if (pricing) {
    queryParams.append('pr', pricing);
  }

  urlWithPayload += `?${queryParams.toString()}`;

  return await customFetch(urlWithPayload, {
    method: 'GET',
  });
}

/**
 * Fetch region activities.
 *
 * @export
 * @function
 * @name HTTP_REQUEST_REGION
 * @param payload
 * @returns {<Response>}
 */
export async function HTTP_REQUEST_REGION(payload) {
  const newurl = `${REGION_URL}${payload}`;
  return await customFetch(newurl, {
    method: 'GET',
  });
}

/**
 * Fetch business activities.
 *
 * @export
 * @function
 * @name HTTP_REQUEST_CITY
 * @param payload
 * @returns {<Response>}
 */
export async function HTTP_REQUEST_CITY(payload) {
  const newurl = `${CITY_URL}${payload}`;
  return await customFetch(newurl, {
    method: 'GET',
  });
}
