import {LEARN, UPDATE_OPTION_STATE, RESET_LEARN_STATE} from 'app/constants/actionTypes';

const initState = {
  isOpen: true,
  lead: false,
  leadsearch: false,
  leads: false,
  addlead: false,
  enrichlead: false,
  template: false,
  automation: false,
  email: false,
};

/**
 * Activity Reducer.
 *
 * @function
 * @name LearnReducer
 * @param state
 * @param type
 * @param payload
 * @returns {object}
 */
export function LearnReducer(state = initState, {type, payload}) {
  switch (type) {
    case LEARN:
      return {
        ...state,
        ...payload,
      };
    case RESET_LEARN_STATE:
      return {
        ...initState,
        isOpen: false,
      }
    case UPDATE_OPTION_STATE:
      const {option, value} = payload;
      return {
        ...state,
        [option]: value,
      };
    default:
      return state;
  }
}
// import {LEARN, UPDATE_OPTION_STATE} from 'app/constants/actionTypes';

// const initState = {
//   isOpen: true,
//   lead: false,
//   leadsearch: false,
//   leads: false,
//   addlead: false,
//   enrichlead: false,
//   template: false,
//   automation: false,
//   email: false,
// };

// /**
//  * Activity Reducer.
//  *
//  * @function
//  * @name LearnReducer
//  * @param state
//  * @param type
//  * @param payload
//  * @returns {object}
//  */
// export function LearnReducer(state = initState, {type, payload}) {
//   switch (type) {
//     case LEARN:
//       console.log(
//         'LearnReducer type',
//         type,
//         'LearnReducer payload',
//         payload,
//         'LearnReducer STATE',
//         state,
//         'LearnReducer INITSTATE',
//         initState
//       );
//       state = {
//         ...state,
//         ...payload,
//       };
//       break;
//     case UPDATE_OPTION_STATE:
//       console.log(
//         'ACTION REDUCER type',
//         type,
//         'LearnReducer payload',
//         payload,
//         'LearnReducer STATE',
//         state,
//         'LearnReducer INITSTATE',
//         initState
//       );
//       const {option, value} = payload;
//       return {
//         ...state,
//         [option]: value,
//       };
//     default:
//       return state;
//   }
//   return state;
// }
