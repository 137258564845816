import {combineReducers} from 'redux';
import {persistStore} from 'redux-persist';
import Cookies from 'js-cookie';
import {AuthReducer} from './authReducer';
import {ProfileReducer} from './profileReducer';
import {ErrorReducer} from './errorReducer';
import {BusinessReducer} from './businessReducer';
import {ActivityReducer} from './activityReducer';
import {LearnReducer} from './learnReducer';

export const appReducer = combineReducers({
  AUTH_REDUCER: AuthReducer,
  PROFILE_REDUCER: ProfileReducer,
  ERROR_REDUCER: ErrorReducer,
  BUSINESS_REDUCER: BusinessReducer,
  ACTIVITY_REDUCER: ActivityReducer,
  LEARN_REDUCER: LearnReducer,
});

export const rootReducer = (state, action) => {
  if (action.type === 'AUTH_LOGOUT') {
    [
      'persist:root',
      '_token',
      'fmschatid',
      'isOpen',
      'lead',
      'leadsearch',
      'leads',
      'addlead',
      'enrichlead',
      'template',
      'automation',
      'email',
    ].forEach(Cookies.remove);
  }

  return appReducer(state, action);
};
