// const useParams = require('react-router-dom')
import React, {useEffect, useState} from 'react';
import {useParams, useNavigate, useLocation, useSearchParams} from 'react-router-dom';

const withRouter = (WrappedComponent, props) =>
  function (props) {
    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const [search, setSearch] = useState(Object.fromEntries([...searchParams]));
    useEffect(() => {
      const currentParams = Object.fromEntries([...searchParams]);
      setSearch(currentParams);
    }, [searchParams]);
    const match = {
      params,
    };
    return (
      <WrappedComponent
        {...props}
        params={params}
        match={match}
        location={location}
        navigate={navigate}
        search={search}
        // etc...
      />
    );
  };

export default withRouter;
