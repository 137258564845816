import React from 'react';
import {HTTP_REQUEST_VERSION} from 'app/services/httpClient/data.service';

class CacheBuster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isLatestVersion: true,
    };
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      const currentVersion = VERSION;
      fetch('/meta.json')
        .then((response) => response.json())
        .then((meta) => {
          const latestVersion = meta.version;
          // console.log('CACHE BUSTER - Current:', currentVersion, 'Meta:', latestVersion);
          const hasRefreshed = localStorage.getItem('refreshed') === 'true';
          // console.log('Has refreshed:', hasRefreshed);
          if (latestVersion !== currentVersion && !hasRefreshed) {
            // console.log('Triggering refresh...');
            this.refreshCacheAndReload();
          } else {
            // console.log('No need to refresh.');
            localStorage.removeItem('refreshed');
            this.setState({loading: false, isLatestVersion: latestVersion === currentVersion});
          }
        });
    }
  }

  refreshCacheAndReload() {
    if (typeof window !== 'undefined') {
      localStorage.setItem('refreshed', 'true');
      if (caches) {
        caches.keys().then((names) => {
          for (const name of names) {
            caches.delete(name);
          }
        });
      }
      window.location.reload(true);
    }
  }

  render() {
    const {loading, isLatestVersion} = this.state;
    return this.props.children({
      loading,
      isLatestVersion,
      refreshCacheAndReload: this.refreshCacheAndReload,
    });
  }
}

export default CacheBuster;
// import packageJson from '../package.json';

// global.appVersion = VERSION;

// version from response - first param, local version second param
// const semverGreaterThan = (versionA, versionB) => {
//   const versionsA = versionA.split(/\./g);

//   const versionsB = versionB.split(/\./g);
//   while (versionsA.length || versionsB.length) {
//     const a = Number(versionsA.shift());

//     const b = Number(versionsB.shift());
//     // eslint-disable-next-line no-continue
//     if (a === b) continue;
//     // eslint-disable-next-line no-restricted-globals
//     return a > b || isNaN(b);
//   }
//   return false;
// };

// console.log('semverGreaterThan', semverGreaterThan);

// class CacheBuster extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       loading: true,
//       isLatestVersion: true,
//       refreshCacheAndReload: () => {
//         // console.log('Clearing cache and hard reloading...');
//         if (caches) {
//           // Service worker cache should be cleared with caches.delete()
//           caches.keys().then((names) => {
//             // console.log('CACHE NAME', names)
//             for (const name of names) caches.delete(name);
//             caches.delete();
//           });
//           caches.delete();
//         }
//         this.setState({loading: true, latestVersion: true});
//         // delete browser cache and hard reload
//         if (typeof window !== 'undefined') {
//         window.location.reload(true);
//         }
//       },
//     };
//   }

//   async componentDidMount() {
//     // console.log('fetching meta');
//     fetch('/meta.json')
//       .then((response) => response.json())
//       .then((meta) => {
//         const latestVersion = meta.version;
//         const currentVersion = VERSION;
//         // console.log('Meta response', meta, latestVersion, currentVersion);
//         // const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion);
//         if (latestVersion > currentVersion) {
//           // console.log(`We have a new version - ${latestVersion}. Should force refresh`);
//           this.setState({loading: false, isLatestVersion: false});
//         } else {
//           // console.log(
//           //   `You already have the latest version - ${latestVersion}. No cache refresh needed.`
//           // );
//           this.setState({loading: false, isLatestVersion: true});
//         }
//       });
//   }

//   render() {
//     const {loading, isLatestVersion, refreshCacheAndReload} = this.state;
//     return this.props.children({loading, isLatestVersion, refreshCacheAndReload});
//   }
// }

// export default CacheBuster;
