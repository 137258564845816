import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import IconButton from '@mui/material/IconButton';
import config from './config';

import Messages from './Messages';
import ChatInput from './ChatInput';

// require('../styles/ChatApp.css');

class ChatApp extends React.Component {
  // socket = {};
  constructor(props) {
    super(props);
    this.state = { messages: [] };
  }

  render() {
    return (
      //   <div className="container">
      //   </div>
      <Grid container direction="column">
        <Grid
          container
          xs={12}
          style={{
            minHeight: '69px',
            backgroundColor: '#003671',
            borderRadius: '4px 4px 0px 0px',
          }}
        >
          <Grid item xs={3} style={{ padding: '16px 4px 8px 16px' }}>
            <img
              src="https://storage.googleapis.com/find-me-sales-bucket/assets/admin/team/willheadshot.png"
              alt="Will Headshot"
              loading="lazy"
              width="322"
              height="322"
              style={{
                maxHeight: '40px',
                maxWidth: '40px',
                border: '1px solid #ffffff',
                borderRadius: '50px',
              }}
            />
          </Grid>
          <Grid item xs={9}>
            <Grid xs={12}>
              <Typography
                variant="body2"
                paragraph
                align="justify"
                style={{
                  marginBottom: '0px',
                  paddingTop: '10px',
                  paddingBottom: '2px',
                  color: '#ffffff',
                  fontSize: '16px',
                }}
              >
                Will Crosthwait
                <IconButton
                  href="https://www.linkedin.com/in/willcrosthwait/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ width: '16px', height: '16px', marginTop: '-1px' }}
                  size="large"
                >
                  <LinkedInIcon
                    fontSize="inherit"
                    style={{
                      fontSize: '12px',
                      padding: '1px',
                      textAlign: 'center',
                      color: '#0966c2',
                      //   marginBottom: '-3px',
                      height: '16px',
                      width: '16px',
                      backgroundColor: '#ffffff',
                      borderRadius: '50%',
                      //   padding: '0px, 4px',
                    }}
                  />
                </IconButton>
              </Typography>
            </Grid>
            <Grid container xs={12} style={{ marginTop: '-3px' }}>
              <Grid item xs={1}>
                <div
                  style={{
                    height: '12px',
                    width: '12px',
                    marginTop: '0.5rem',
                    backgroundColor: '#41C08B',
                    borderRadius: '50%',
                    display: 'inline-block',
                    border: '1px solid #ffffff',
                    animation: 'pulse 2s infinite',
                  }}
                />
              </Grid>
              <Grid item xs={11}>
                <Typography
                  // variant="body1"
                  paragraph
                  align="justify"
                  style={{
                    paddingTop: '0.2rem',
                    marginBottom: '0px',
                    color: '#ffffff',
                    fontWeight: '300',
                    fontSize: '14px',
                  }}
                >
                  Online
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Messages messages={this.props.messages} height="332px" />
        <ChatInput onSend={this.props.sendHandler} />
      </Grid>
    );
  }
}
ChatApp.defaultProps = {
  username: 'Anonymous',
  messages: [],
};

export default ChatApp;
