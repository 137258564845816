/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withNamespaces} from 'react-i18next';
import {REGISTER_ACTION, CLEAR_AUTH_ACTION} from 'app/actions/authAction';
import {PROFILE_ACTION} from 'app/actions/profileAction';
import {HTTP_REQUEST_EMAIL_VERIFY} from 'app/services/httpClient/auth.service';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Caps from 'app/common/caps';
import FormControlLabel from '@mui/material/FormControlLabel';
import {HTTP_COMPLETE_PROFILE} from 'app/services/httpClient/profile.service';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import HelmetMaker from 'app/utils/Helmet';
import ReactGA from 'react-ga4';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import {toast} from 'react-toastify';
import {ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import withRouter from 'app/utils/withRouter';

const moment = require('moment');

function mapStateToProps(state) {
  return {
    GET_PROFILE_STATE: state.PROFILE_REDUCER.Profile,
    GET_AUTH_STATE: state.AUTH_REDUCER.Auth,
    GET_ERRORS_STATE: state.ERROR_REDUCER.Errors,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    DISPATCH_REGISTER: (payload) => dispatch(REGISTER_ACTION(payload)),
    DISPATCH_CLEAR_AUTH: () => dispatch(CLEAR_AUTH_ACTION()),
    DISPATCH_PROFILE: (token) => dispatch(PROFILE_ACTION(token)),
  };
}

class CompleteSignup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nameerror: false,
      Payload: {
        firstName: null,
        lastName: null,
        fullName: null,
        nameerror: false,
        businessName: null,
        jobTitle: null,
        agreeTerms: false,
        email: null,
        phone: null,
        password: null,
        confirm_password: null,
        Payload: {},
        sub_id: 'undefined',
        sub_created: 'undefined',
        current_period_end: 'undefined',
        current_period_start: 'undefined',
        stripeCustomerId: 'undefined',
        cancel_at: 'undefined',
        cancel_at_period_end: false,
        canceled_at: 'undefined',
        paymentMethod_id: 'undefined',
        paymentMethod_brand: 'undefined',
        last4: 'undefined',
        exp_month: 'undefined',
        status: 'undefined',
        max_profiles: 1,
        exp_year: 'undefined',
        nameOnCard: 'undefined',
        billingAddress: 'undefined',
        city: 'undefined',
        postCodeZip: 'undefined',
        country: 'undefined',
        subscription: 'free',
        mailbox: {
          user: null,
          password: {},
          outgoinghost: null,
          incominghost: null,
        },
      },
      questionsLeft: this.calculateQuestionsLeft(props.GET_PROFILE_STATE, {}),
    };
    // bind event handlers in class components.
    this.HandleInputChange = this.HandleInputChange.bind(this);
    this.HandleCheckboxChange = this.HandleCheckboxChange.bind(this);
    this.HandleSubmit = this.HandleSubmit.bind(this);
    this.calculateQuestionsLeft = this.calculateQuestionsLeft.bind(this);
  }

  async componentDidMount() {
    const {GET_ERRORS_STATE, DISPATCH_CLEAR_AUTH, GET_AUTH_STATE, DISPATCH_PROFILE, navigate} =
      this.props;
    await DISPATCH_PROFILE(GET_AUTH_STATE.token);

    // console.log('COMPLETE CDM', this.props.GET_PROFILE_STATE);
    this.setState({
      questionsLeft: this.calculateQuestionsLeft(this.props.GET_PROFILE_STATE, this.state.Payload),
    });
    // console.log('COMPLETE CDM', this.props.GET_PROFILE_STATE);
    const {isAuthenticated, token} = GET_AUTH_STATE;
    if (isAuthenticated && token !== null) {
      navigate('/complete-signup');
    }
    if (GET_ERRORS_STATE.length !== 0) {
      toast.error(GET_ERRORS_STATE);
      DISPATCH_CLEAR_AUTH();
    }
  }

  componentDidUpdate(prevState, prevProps) {
    const {GET_ERRORS_STATE, GET_AUTH_STATE} = this.props;
    if (
      GET_AUTH_STATE &&
      GET_AUTH_STATE.isAuthenticated !== prevState.GET_AUTH_STATE.isAuthenticated
    ) {
      this.props.navigate('/success/?m=free-trial');
    }
    if (this.props.GET_PROFILE_STATE !== prevProps.GET_PROFILE_STATE) {
      const newQuestionsLeft = this.calculateQuestionsLeft(this.props.GET_PROFILE_STATE, this.state.Payload);
      if (this.state.questionsLeft !== newQuestionsLeft) {
        this.setState({
          questionsLeft: newQuestionsLeft,
        });
      }
    }
  }

  HandleInputChange(event) {
    const date = moment().unix();
    const future = moment().add(1, 'M').unix();
    this.setState((prevState) => ({
      nameerror: false,
      Payload: {
        ...this.state.Payload,
        [event.target.name]: event.target.value,
        current_period_start: date,
        current_period_end: future,
        questionsLeft: this.calculateQuestionsLeft(this.props.GET_PROFILE_STATE, this.state.Payload),
      },
    }));
    const newQuestionsLeft = this.calculateQuestionsLeft(this.props.GET_PROFILE_STATE, this.state.Payload);

    // Update the state only if questionsLeft actually changes
    if (this.state.questionsLeft !== newQuestionsLeft) {
      this.setState((prevState) => ({
        nameerror: false,
        Payload: {
          ...this.state.Payload,
          [event.target.name]: event.target.value,
          current_period_start: date,
          current_period_end: future,
        },
        questionsLeft: newQuestionsLeft,
      }));
    } else {
      // Only update the Payload part of the state
      this.setState((prevState) => ({
        nameerror: false,
        Payload: {
          ...this.state.Payload,
          [event.target.name]: event.target.value,
          current_period_start: date,
          current_period_end: future,
        },
      }));
    }
  }

  calculateQuestionsLeft(profileState, payloadState = {}) {
    let count = 0;

    // Check for fullName
    if (!profileState.firstName && !payloadState.fullName) {
      count += 1;
    }

    // Check for businessName
    if (!profileState.businessName && !payloadState.businessName) {
      count += 1;
    }

    // Check for password
    if (!profileState.password && !payloadState.password) {
      count += 1;
    }

    return count;
  }

  HandleCheckboxChange(event) {
    toast.dismiss();
    if (this.state.Payload.agreeTerms === false) {
      this.setState({
        Payload: {
          ...this.state.Payload,
          agreeTerms: true,
        },
      });
    } else {
      this.setState({
        Payload: {
          ...this.state.Payload,
          agreeTerms: false,
        },
      });
    }
  }

  async HandleSubmit(event) {
    event.preventDefault();
    // if (this.state.Payload.agreeTerms === false) {
    //   toast.error('You must agree with our Terms and Conditions to register.');
    // } else {

    let firstName = '';
    let lastName = '';
    let all;
    if (this.state.Payload.fullName) {
      const name = this.state.Payload.fullName.trim();
      all = name.split(' ').length;
      // const name = req.body.fullName;
      const first = name.split(' ')[0];
      const last = name.substr(name.indexOf(' ') + 1);
      firstName = Caps(first);
      lastName = Caps(last);
    }
    if (all < 2) {
      // setLoading(false);
      // setNameError(true);
      this.setState({
        nameerror: true,
      });
      if (typeof window !== 'undefined') {
        window.scrollTo(0, 60);
      }
      toast.error('Please enter your full name');
    } else {
      const profile = this.props.GET_PROFILE_STATE;
      const payload = {
        _id: profile._id,
        email: profile.email,
        firstName: profile.firstName ? profile.firstName : firstName,
        lastName: profile.lastName ? profile.lastName : lastName,
        businessName: this.state.Payload.businessName,
      };
      if (this.state.Payload.password) {
        payload.password = this.state.Payload.password;
      }
      // console.log('SUBMIT', payload, profile);
      const {GET_AUTH_STATE, DISPATCH_PROFILE} = this.props;
      HTTP_COMPLETE_PROFILE(payload)
        .then((response) => response.json())
        .then((response) => {
          const reslength = response.length;
          if (reslength !== 0) {
            toast.success('Thank you for completing registration.');
            this.props.navigate('/companies');
            DISPATCH_PROFILE(GET_AUTH_STATE.token);
          }
          return response;
        })
        .catch((error) => {
          // handle error
          console.log(error);
          return;
        });
    }
  }

  render() {
    const {questionsLeft} = this.state;
    const {t: lang, navigate} = this.props;
    const helmetload = {
      title: 'Complete Signup | Find Me Sales',
      description:
        'Complete your sign up for your free Find Me Sales trial of 50 free credits in a month.',
      url: 'https://findmesales.com/complete',
      // image:
      //   'https://storage.cloud.google.com/find-me-sales-bucket/assets/leadsearchhome.jpg',
      keywords: 'Leads, Sales',
    };
    let subtitle;
    if (questionsLeft > 1) {
      subtitle = `You're only ${questionsLeft} answers away from finding your first sales`;
    } else if (questionsLeft === 1) {
      subtitle = "You're only 1 answer away from finding your first sales";
    } else {
      subtitle = "You're only 1 button click away from finding your first sales";
    }
    return (
      <Fragment>
        <HelmetMaker props={helmetload} />
        <Container
          // className="register-container"
          component="main"
          maxWidth="sm"
          style={{paddingBottom: '200px', minWidth: '97%'}}>
          <div className="regpaper">
            <Grid container>
              <Grid
                container
                xs={12}
                // md={6}
                direction="column"
                alignItems="center"
                justifyContent="center"
                style={{padding: '0px 5%'}}>
                <Grid item style={{maxWidth: '480px'}}>
                  <ValidatorForm
                    id="regform"
                    onError={(errors) => {
                      if (typeof window !== 'undefined') {
                        window.scrollTo(0, 260), toast.error(errors);
                      }
                    }}
                    onSubmit={this.HandleSubmit}>
                    <CssBaseline />
                    <Typography
                      className="registerheader"
                      component="h1"
                      variant="h1"
                      style={{fontWeight: '400', paddingBottom: '24px'}}>
                      Complete Signup
                    </Typography>
                    {questionsLeft >= 0 && (
                      <Typography component="h2" variant="h6" style={{paddingBottom: '24px'}}>
                        {subtitle}
                      </Typography>
                    )}
                    <Grid container spacing={2}>
                      {!this.props.GET_PROFILE_STATE.firstName ? (
                        <Grid item xs={12}>
                          <TextValidator
                            // autoComplete="fullName"
                            name="fullName"
                            value={this.state.Payload.fullName}
                            variant="outlined"
                            onChange={this.HandleInputChange}
                            error={this.state.nameerror}
                            fullWidth
                            autoFocus
                            id="firstName"
                            label="Full Name"
                            validators={['required']}
                            errorMessages={['This field is required']}
                          />
                        </Grid>
                      ) : null}
                      <Grid item xs={12}>
                        <TextValidator
                          autoComplete="businessName"
                          name="businessName"
                          value={this.state.Payload.businessName}
                          variant="outlined"
                          onChange={this.HandleInputChange}
                          fullWidth
                          id="businessName"
                          label="Business Name"
                          validators={['required']}
                          errorMessages={['This field is required']}
                        />
                      </Grid>
                      {!this.props.GET_PROFILE_STATE.password ? (
                        <Grid item xs={12}>
                          <TextValidator
                            variant="outlined"
                            fullWidth
                            onChange={this.HandleInputChange}
                            name="password"
                            label="Choose password"
                            type="password"
                            id="password"
                            value={this.state.Payload.password}
                            // autoComplete="current-password"
                            validators={['required', 'minStringLength:8']}
                            errorMessages={['This field is required', '8 Characters Minimum']}
                          />
                        </Grid>
                      ) : null}
                    </Grid>
                    <Button
                      type="submit"
                      form="regform"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className="registersubmit">
                      Complete Signup
                    </Button>
                    <Grid container justifyContent="center" style={{textAlign: 'center'}}>
                      <Grid item xs={12}>
                        <Typography
                          variant="body2"
                          paragraph
                          align="justify"
                          style={{textAlign: 'center'}}>
                          No card required
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          variant="body2"
                          paragraph
                          align="justify"
                          style={{
                            textAlign: 'center',
                            fontSize: '12px',
                            padding: '0px',
                          }}>
                          By clicking 'Complete Signup' you agree to our{' '}
                          <Link
                            target="_blank"
                            href="/terms"
                            variant="body1"
                            align="left"
                            style={{fontSize: '12px'}}
                            className="pagelink">
                            Terms
                          </Link>{' '}
                          and{' '}
                          <Link
                            target="_blank"
                            href="/privacy"
                            variant="body1"
                            style={{fontSize: '12px'}}
                            align="left"
                            className="pagelink">
                            Privacy Policy
                          </Link>
                        </Typography>
                      </Grid>
                      {/* <Grid item xs={12} style={{textAlign: 'center'}}>
                        <Link
                          style={{cursor: 'pointer', fontSize: '12px'}}
                          onClick={() => {
                            this.props.navigate('login');
                          }}
                          variant="body2">
                          Already have an account? Sign in
                        </Link>
                      </Grid> */}
                    </Grid>
                  </ValidatorForm>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Container>
      </Fragment>
    );
  }
}

CompleteSignup.propTypes = {
  DISPATCH_REGISTER: PropTypes.func.isRequired,
  DISPATCH_CLEAR_AUTH: PropTypes.func.isRequired,
  GET_ERRORS_STATE: PropTypes.array.isRequired,
  GET_AUTH_STATE: PropTypes.object.isRequired,
  // t: PropTypes.func.isRequired,
  navigate: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CompleteSignup));
