/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Fab from '@mui/material/Fab';
import { PROFILE_ACTION } from 'app/actions/profileAction';
import { LOGOUT_ACTION } from 'app/actions/authAction';
// import {withStyles} from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import withRouter from 'app/utils/withRouter';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { toast } from 'react-toastify';
import Button from '@mui/material/Button';
import Badge from '@mui/material/Badge';
import ClearIcon from '@mui/icons-material/Clear';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import AddIcon from '@mui/icons-material/Add';

function mapStateToProps(state) {
  return {
    GET_PROFILE_STATE: state.PROFILE_REDUCER.Profile,
    GET_AUTH_STATE: state.AUTH_REDUCER.Auth,
    GET_ERRORS_STATE: state.ERROR_REDUCER.Errors,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    DISPATCH_PROFILE: (token) => dispatch(PROFILE_ACTION(token)),
    DISPATCH_LOGOUT_ACTION: () => dispatch(LOGOUT_ACTION()),
  };
}

class AddAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: 'active',
      anchorEl: null,
      open: false,
      live: false,
      email: '',
      name: '',
      message: '',
      username: '',
      messages: [],
      newmessage: false,
      msgcount: 0,
    };
    this.AddStatus = this.AddStatus.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    // this.HandleSubmit = this.HandleSubmit.bind(this);
    // this.sendHandler = this.sendHandler.bind(this);
    this.OpenMessages = this.OpenMessages.bind(this);
  }

  async componentDidMount() {
    const { DISPATCH_PROFILE, GET_AUTH_STATE } = this.props;
    await DISPATCH_PROFILE(GET_AUTH_STATE.token);
    const { GET_PROFILE_STATE } = this.props;
    // let chatid = '';
    // if (GET_PROFILE_STATE._id) {
    //   // if (Cookies.get('newmsgs')) {
    //   //   chatid = GET_PROFILE_STATE._id;
    //   //   this.setState({msgcount: Cookies.get('newmsgs')});
    //   // } else {
    //   //   Cookies.set('newmsgs', 0);

    //   // }
    //   chatid = GET_PROFILE_STATE._id;
    // } else if (Cookies.get('fmschatid')) {
    //   chatid = Cookies.get('fmschatid');
    //   // this.setState({msgcount: Cookies.get('newmsgs')});
    // } else {
    //   const newid = uuidv4();
    //   Cookies.set('fmschatid', newid);
    //   // Cookies.set('newmsgs', 0);
    //   chatid = newid;
    // }
    // // console.log('CHAT ID', chatid);
    // // Connect to the server
    // this.socket = io(config.api, {query: `username=${chatid}`}).connect(
    //   console.log('Connected to Find Me Sales live chat')
    // );

    // this.socket.on('private', ({username, message}) => {
    //   console.log('PRIVATE MESSAGE RECEIVED', username, message);
    //   const msgcount = this.state.msgcount + 1;
    //   const msg = {
    //     message,
    //     username,
    //     room: Cookies.get('fmschatid'),
    //     recipient: Cookies.get('fmschatid'),
    //     date: Date(),
    //   };
    //   if (this.state.open === true) {
    //     this.addMessage(msg);
    //   } else {
    //     this.setState({newmessage: true, msgcount});
    //     toast.info(msg.message, {
    //       onClick: this.OpenMessages,
    //     });
    //     this.addMessage(msg);
    //     // Cookies.set('newmsgs', msgcount);
    //   }
    // });

    // this.socket.on('fmsonline', (isOnline) => {
    //   this.setState({live: isOnline});
    // });

    // const response = await HTTP_REQUEST_CHAT(Cookies.get('fmschatid'));
    // if (response && response.status === 200) {
    //   const res = await response.json();
    //   this.setState({messages: res});
    // } else {
    //   toast.error('There was an issue submitting your message.');
    // }
  }

  componentDidUpdate(prevProps) {
    const { GET_PROFILE_STATE } = this.props;
    // if (GET_PROFILE_STATE._id !== prevProps.GET_PROFILE_STATE._id) {
    //   if (GET_PROFILE_STATE._id && GET_PROFILE_STATE._id !== Cookies.get('fmschatid')) {
    //     Cookies.set('fmschatid', GET_PROFILE_STATE._id);
    //   }
    // }
  }

  handleClick(event) {
    this.setState({ open: true, anchorEl: event.currentTarget });
  }

  handleClose() {
    this.setState({ anchorEl: false });
  }

  AddStatus(status) {
    this.setState({
      status,
    });
  }

  // async sendHandler(message) {
  //   const messageObject = {
  //     username: Cookies.get('fmschatid'),
  //     message,
  //   };

  //   const payload = {
  //     username: Cookies.get('fmschatid'),
  //     room: Cookies.get('fmschatid'),
  //     message,
  //     recipient: 'findmesales',
  //     date: Date(),
  //     read: true,
  //   };
  //   // Emit the message to the server

  //   // await HTTP_ADD_CHAT(payload)
  //   //   .then((response) => response.json())
  //   //   .then((response) => {
  //   //     // this.socket.emit('client:message', messageObject);
  //   //     // this.socket.emit('private message', {
  //   //     //   content: messageObject,
  //   //     //   to: 'Find Me Sales',
  //   //     // });
  //   //     this.socket.emit('private', {
  //   //       content: messageObject,
  //   //       to: 'findmesales',
  //   //     });
  //   //     messageObject.fromMe = true;
  //   //     this.addMessage(messageObject);
  //   //   })
  //   //   .catch((error) => {
  //   //     // handle error
  //   //     toast.error('There was an issue submitting your message.');
  //   //     console.log(error);
  //   //   });
  // }

  // // addMessage(message) {
  // //   // Append the message to the component state
  // //   const {messages} = this.state;
  // //   messages.push(message);
  // //   this.setState({messages});
  // // }
  // addMessage(message) {
  //   // Append the message to the component state in an immutable way
  //   this.setState((prevState) => ({
  //     messages: [...prevState.messages, message],
  //   }));
  // }

  OpenMessages() {
    toast.dismiss();
    this.setState({ newmessage: false, msgcount: 0, open: true });
  }

  // async HandleSubmit(event) {
  //   event.preventDefault();
  //   // const payload = {
  //   //   email: this.state.email,
  //   //   name: this.state.name,
  //   //   message: this.state.message,
  //   // };
  //   // const response = await HTTP_SUBMIT_MESSAGE(payload);
  //   // if (response && response.status === 200) {
  //   //   toast.success('Thank you for submitting your message');
  //   //   this.setState({open: false});
  //   // } else {
  //   //   toast.error(
  //   //     'There was an issue submitting your message. Please refresh the page and try again.'
  //   //   );
  //   // }
  // }

  render() {
    const { navigate } = this.props;
    if (this.state.open === false) {
      if (this.state.newmessage === false) {
        return (
          <div>
            <Fab
              aria-label="add-action"
              color="primary"
              style={{
                color: '#ffffff',
                position: 'fixed',
                bottom: '14px',
                right: '86px',
                zIndex: '5000',
              }}
              onClick={this.OpenMessages}
            >
              <AddIcon
                style={{
                  transform: 'scaleX(-1)',
                  MozTransform: 'scaleX(-1)',
                  WebkitTransform: 'scaleX(-1)',
                  msTransform: 'scaleX(-1)',
                  fontSize: '34px',
                }}
              />
            </Fab>
          </div>
        );
      } else {
        return (
          <div>
            <Grid item xs={3} style={{ padding: '16px 4px 8px 16px' }}>
              {/* <Badge
                badgeContent={this.state.msgcount}
                // color="linkedin"
                showZero
                // style={{fontWeight: '90px'}}
                overlap="circle"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}> */}

              <Fab
                aria-label="message"
                color="primary"
                style={{
                  color: '#ffffff',
                  position: 'fixed',
                  bottom: '14px',
                  right: '18px',
                  zIndex: '5000',
                }}
                onClick={this.OpenMessages}
              >
                <Badge
                  color="secondary"
                  overlap="circular"
                  style={{
                    position: 'fixed',
                    bottom: '22px',
                    right: '26px',
                  }}
                  badgeContent={this.state.msgcount}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                >
                  <img
                    src="https://storage.googleapis.com/find-me-sales-bucket/assets/admin/team/willheadshot.png"
                    alt="Will Headshot"
                    loading="lazy"
                    width="322"
                    height="322"
                    style={{
                      maxHeight: '56px',
                      maxWidth: '56px',
                      animation: 'pulse 2s infinite',
                      // boxShadow:
                      //   '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)',
                      border: '1px solid #ffffff',
                      borderRadius: '50px',
                      position: 'fixed',
                      bottom: '14px',
                      right: '18px',
                    }}
                  />
                </Badge>
              </Fab>
            </Grid>
            {/* <Fab
              aria-label="message"
              color="primary"
              style={{color: '#ffffff', position: 'fixed', bottom: '14px', right: '18px'}}
              onClick={() => this.setState({newmessage: false, open: true})}>
              <ChatIcon
                style={{
                  transform: 'scaleX(-1)',
                  MozTransform: 'scaleX(-1)',
                  WebkitTransform: 'scaleX(-1)',
                  msTransform: 'scaleX(-1)',
                }}
              />
            </Fab> */}
          </div>
        );
      }
    } else if (this.state.live === true) {
      return (
        <div>
          <Container
            style={{
              position: 'fixed',
              bottom: '88px',
              right: '0px',
              width: '328px',
              zIndex: '99999',
            }}
          >
            <Grid>
              <Paper style={{ height: '450px' }}>
                <p>Hello!</p>
                {/* <ChatApp
                  username={Cookies.get('fmschatid')}
                  sendHandler={this.sendHandler}
                  addMessage={this.addMessage}
                  messages={this.state.messages}
                /> */}
              </Paper>
            </Grid>
          </Container>
          <Fab
            aria-label="message"
            color="primary"
            style={{
              color: '#ffffff',
              position: 'fixed',
              bottom: '14px',
              right: '86px',
            }}
            onClick={() => this.setState({ open: false })}
          >
            <ClearIcon />
          </Fab>
        </div>
        //   <p
        //   style={{
        //     maxWidth: '45%',
        //     padding: '14px',
        //     margin: '14px',
        //     textAlign: 'left',
        //     borderStyle: 'solid',
        //     borderColor: '#003671',
        //     borderWidth: 'thin',
        //     backgroundColor: '#ffffff',
        //     // backgroundColor: '#003671',
        //     borderRadius: '15px 15px 0px 15px',
        //     position: 'fixed',
        //     zIndex: 0,
        //   }}>
        //   Hello!
        // </p>
      );
    } else {
      const buttonStyle = {
        color: '#ffffff', // White text and icon
        justifyContent: 'space-between', // Spacing between text and icon
        textTransform: 'capitalize', // Capitalize text
      };
      return (
        <div>
          <Container
            style={{
              position: 'fixed',
              bottom: '88px',
              right: '64px',
              width: '328px',
              zIndex: '99999',
            }}
          >
            <Grid container direction="column" spacing={1}>
              <Grid item>
                <Button
                  variant="contained"
                  onClick={() => {
                    this.setState({ open: false }),
                      this.props.navigate('/actions?a=add');
                  }}
                  fullWidth
                  endIcon={
                    <ArrowForwardIosSharpIcon
                      style={{ marginRight: '2px', color: '#ffffff' }}
                    />
                  }
                  style={buttonStyle}
                >
                  Add Action
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  onClick={() => {
                    this.setState({ open: false }),
                      this.props.navigate('/companies?a=add');
                  }}
                  fullWidth
                  endIcon={
                    <ArrowForwardIosSharpIcon
                      style={{ marginRight: '2px', color: '#ffffff' }}
                    />
                  }
                  style={buttonStyle}
                >
                  Add Lead
                </Button>
              </Grid>
            </Grid>
          </Container>
          <Fab
            aria-label="message"
            color="primary"
            style={{
              color: '#ffffff',
              position: 'fixed',
              bottom: '14px',
              right: '86px',
            }}
            onClick={() => this.setState({ open: false })}
          >
            <ClearIcon />
          </Fab>
        </div>
      );
    }
  }
}

AddAction.defaultProps = {
  DISPATCH_LOGOUT_ACTION: PropTypes.any,
  navigate: PropTypes.any,
};

AddAction.propTypes = {
  // GET_AUTH_STATE: PropTypes.object.isRequired,
  // GET_PROFILE_STATE: PropTypes.object.isRequired,
  // GET_ERRORS_STATE: PropTypes.array.isRequired,
  // DISPATCH_LOGOUT_ACTION: PropTypes.any,
  // getAuth: PropTypes.func.isRequired,
  // getProfile: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AddAction));
