/* eslint-disable react/prefer-stateless-function */
import React from 'react';

import Cookies from 'js-cookie';
import Grid from '@mui/material/Grid';

class Message extends React.Component {
  render() {
    // Was the message sent by the current user. If so, add a css class
    let fromMe;
    if (this.props.username === Cookies.get('fmschatid')) {
      // fromMe = this.props.username === Cookies.get('fmschatid') ? 'from-me' : '';
      fromMe = 'from-me';
    } else if (this.props.fromMe) {
      fromMe = 'from-me';
    } else {
      fromMe = '';
    }

    return (
      <div className={`message ${fromMe}`}>
        {/* <div className="username"> */}
        {/* <Grid className="username">{this.props.username}</Grid> */}
        {/* </div> */}
        {/* <div className="message-body"> */}
        <Grid className="message-body">{this.props.message}</Grid>
        {/* </div> */}
      </div>
    );
  }
}

Message.defaultProps = {
  message: '',
  username: '',
  fromMe: false,
};

export default Message;
