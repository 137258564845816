import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {hasErrors} from 'support/helpers/front';
import {connect} from 'react-redux';
import {GuestNavbar} from 'app/layouts/guestnavbar';
import {DashNavbar} from 'app/layouts/dashnavbar';
import {PROFILE_ACTION} from 'app/actions/profileAction';
import {LOGOUT_ACTION} from 'app/actions/authAction';

function mapStateToProps(state) {
  return {
    GET_PROFILE_STATE: state.PROFILE_REDUCER.Profile,
    GET_AUTH_STATE: state.AUTH_REDUCER.Auth,
    GET_ERRORS_STATE: state.ERROR_REDUCER.Errors,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    DISPATCH_PROFILE: (token) => dispatch(PROFILE_ACTION(token)),
    DISPATCH_LOGOUT_ACTION: () => dispatch(LOGOUT_ACTION()),
  };
}

class Nav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: 'active',
    };
    this.AddStatus = this.AddStatus.bind(this);
  }

  componentDidMount() {
    const {GET_PROFILE_STATE, GET_AUTH_STATE, getAuth, getProfile} = this.props;
    this.setState({
      status: 'active',
    });
    getAuth(GET_AUTH_STATE);
    if (GET_PROFILE_STATE.email !== null) {
      getProfile(GET_PROFILE_STATE);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      GET_AUTH_STATE,
      getAuth,
      GET_ERRORS_STATE,
      GET_PROFILE_STATE,
      getProfile,
      DISPATCH_LOGOUT_ACTION,
    } = this.props;

    // Check for errors and if they have changed
    if (hasErrors(GET_ERRORS_STATE) && !hasErrors(prevProps.GET_ERRORS_STATE)) {
      DISPATCH_LOGOUT_ACTION();
    }

    // Check if profile email is not null and status has changed
    if (
      GET_PROFILE_STATE.email !== null &&
      prevProps.GET_PROFILE_STATE.status !== GET_PROFILE_STATE.status
    ) {
      this.AddStatus(GET_PROFILE_STATE.status);

      // Added check: Only call getProfile if the profile has changed
      if (prevProps.GET_PROFILE_STATE !== GET_PROFILE_STATE) {
        getProfile(GET_PROFILE_STATE);
      }
    }
  }

  // componentDidUpdate(prevProps) {
  //   const {
  //     GET_AUTH_STATE,
  //     getAuth,
  //     GET_ERRORS_STATE,
  //     GET_PROFILE_STATE,
  //     getProfile,
  //     DISPATCH_LOGOUT_ACTION,
  //   } = this.props;
  //   if (hasErrors(GET_ERRORS_STATE)) {
  //     DISPATCH_LOGOUT_ACTION();
  //   }
  //   if (
  //     GET_PROFILE_STATE.email !== null &&
  //     prevProps.GET_PROFILE_STATE.status !== GET_PROFILE_STATE.status
  //   ) {
  //     this.AddStatus(GET_PROFILE_STATE.status);
  //     getProfile(GET_PROFILE_STATE);
  //   }
  // }

  AddStatus(status) {
    this.setState({
      status,
    });
  }

  render() {
    const {GET_AUTH_STATE} = this.props;
    if (typeof window !== 'undefined' && window.location.pathname === '/verifypayment') {
      return null;
    } else if (GET_AUTH_STATE.isAuthenticated === true) {
      // if (this.state.status !== 'active') {
      //   return <RenewNavbar />;
      // } else {
      //   return <DashNavbar />;
      // }
      return <DashNavbar />;
    } else {
      return <GuestNavbar />;
    }
  }
}

Nav.defaultProps = {
  DISPATCH_LOGOUT_ACTION: PropTypes.any,
};

Nav.propTypes = {
  GET_AUTH_STATE: PropTypes.object.isRequired,
  GET_PROFILE_STATE: PropTypes.object.isRequired,
  GET_ERRORS_STATE: PropTypes.array.isRequired,
  DISPATCH_LOGOUT_ACTION: PropTypes.any,
  getAuth: PropTypes.func.isRequired,
  getProfile: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Nav);
