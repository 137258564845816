import React, {Component, useEffect} from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga4';
import {Route, Routes, useLocation} from 'react-router-dom';

function isProbablyBot(userAgent) {
  const botKeywords = ['bot', 'crawl', 'spider', 'curl', 'wget', 'slurp', 'mediapartners'];
  const ogDataFetchers = ['facebookexternalhit', 'facebot', 'pinterestbot']; // Add more as needed

  const isKnownBot = botKeywords.some(keyword => userAgent.toLowerCase().includes(keyword));
  const isOGDataFetcher = ogDataFetchers.some(keyword => userAgent.toLowerCase().includes(keyword));

  if (isKnownBot && !isOGDataFetcher) {
    return true; // It's a bot but not an OG data fetcher
  }
  return false; // Not a bot or an allowed OG data fetcher
}

class GoogleAnalytics extends Component {
  componentDidMount() {
    if (!isProbablyBot(navigator.userAgent)) {
      const {location} = this.props;
      const path = location.pathname + location.search;
      ReactGA.send({hitType: 'pageview', page: path});
    }
  }

  // componentDidUpdate({location: prevLocation}) {
  //   if (!isProbablyBot(navigator.userAgent)) {
  //     const {location} = this.props;
  //     const path = location.pathname + location.search;
  //     if (prevLocation.pathname + prevLocation.search !== path) {
  //       ReactGA.send({hitType: 'pageview', page: path});
  //     }
  //   }
  // }

  render() {
    return null;
  }
}

GoogleAnalytics.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
};

const RouteTracker = function () {
  const location = useLocation();

  useEffect(() => {
    if (!isProbablyBot(navigator.userAgent)) {
      const path = location.pathname + location.search;
      ReactGA.send({hitType: 'pageview', page: path});
    }
  }, [location.pathname, location.search]);

  return null;
};

const Transaction = function (props) {
  const {invoice: inv, sub} = props;

  ReactGA.event({
    name: 'purchase',
    params: {
      currency: inv.currency,
      value: sub.revenue,
      items: [
        {
          item_id: inv.id,
          item_name: inv.name,
          item_category: inv.category,
          price: inv.price,
          quantity: 1,
        },
      ],
      transaction_id: sub.id,
    },
  });
};

const init = (options = {}) => {
  const isGAEnabled = !!'G-DTHTQM9V6V';

  if (isGAEnabled && !isProbablyBot(navigator.userAgent)) {
    ReactGA.initialize('G-DTHTQM9V6V', {
      // debug: true,
      ...options,
    });
  }

  return isGAEnabled;
};

export default {
  GoogleAnalytics,
  RouteTracker,
  init,
  Transaction,
};

// import React, {Component, useState} from 'react';
// import PropTypes from 'prop-types';
// import ReactGA from 'react-ga4';
// import {Route, Routes, useLocation} from 'react-router-dom';

// const isProbablyBot = (userAgent) => {
//   const botIndicators = ['bot', 'crawl', 'spider', 'curl', 'wget', 'slurp', 'mediapartners'];
//   const lowerUserAgent = userAgent.toLowerCase();
//   return botIndicators.some((indicator) => lowerUserAgent.includes(indicator));
// };

// class GoogleAnalytics extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       pageseen: '',
//     };
//   }

//   componentDidMount() {
//     // console.log('ANALYTICS PAGE CHANGE CDM', this.props);
//     // this.logPageChange(this.props.location.pathname, this.props.location.search);
//   }

//   componentDidUpdate({location: prevLocation}) {}

//   render() {
//     return null;
//   }
// }

// GoogleAnalytics.defaultProps = {
//   options: PropTypes.func,
// };

// GoogleAnalytics.propTypes = {
//   location: PropTypes.shape({
//     pathname: PropTypes.string,
//     search: PropTypes.string,
//   }).isRequired,
//   options: PropTypes.func,
// };

// const RouteTracker = function () {
//   const location = useLocation();

//   React.useEffect(() => {
//     // Check if the user is a bot
//     if (!isProbablyBot(navigator.userAgent)) {
//       const path = location.pathname + location.search;
//       ReactGA.send({hitType: 'pageview', page: path});
//     }
//   }, [location.pathname, location.search]);

//   return null; // No need to return any JSX here.
// };

// const Transaction = function (props) {
//   const inv = props.invoice;
//   const {sub} = props;

//   ReactGA.event({
//     name: 'purchase',
//     params: {
//       currency: inv.currency,
//       value: sub.revenue,
//       items: [
//         {
//           item_id: inv.id,
//           item_name: inv.name,
//           item_category: inv.category,
//           price: inv.price,
//           quantity: 1,
//         },
//       ],
//       transaction_id: sub.id,
//     },
//   });
// };

// const init = (options = {}) => {
//   const isGAEnabled = !!'G-DTHTQM9V6V';

//   if (isGAEnabled && !isProbablyBot(navigator.userAgent)) {
//     ReactGA.initialize('G-DTHTQM9V6V', {
//       debug: true,
//       ...options,
//     });
//   }

//   return isGAEnabled;
// };

// export default {
//   GoogleAnalytics,
//   RouteTracker,
//   init,
//   Transaction,
// };
