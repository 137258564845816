// Settings configured here will be merged into the final config object.
export default {
  api:
    process.env.NODE_ENV === 'development' ||
    (typeof window !== 'undefined' && window.location.hostname === 'localhost') ||
    (typeof window !== 'undefined' && window.location.hostname === '127.0.0.1')
      ? 'http://127.0.0.1:4200'
      : 'https://findmesales.com/',
  wsApi:
    process.env.NODE_ENV === 'development' ||
    (typeof window !== 'undefined' && window.location.hostname === 'localhost') ||
    (typeof window !== 'undefined' && window.location.hostname === '127.0.0.1')
      ? 'ws://localhost'
      : 'wss://findmesales.com/',
};
