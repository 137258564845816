import React, {Component} from 'react';
import MUIDataTable from 'mui-datatables';
import {withNamespaces} from 'react-i18next';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import {
  HTTP_UPDATE_NOTIFICATION,
  HTTP_NEW_NOTIFICATIONS,
} from 'app/services/httpClient/gather.service';

const moment = require('moment');

class NotificationTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profile: {},
      items: [],
    };
    this.HandleCustomer = this.HandleCustomer.bind(this);
  }

  componentDidMount(props) {}

  // async componentDidUpdate(prevProps, nextProps, prevState, snapshot) {
  //   if (this.props.profile === null) {
  //     return false;
  //   } else if (prevProps.profile !== this.props.profile) {
  //     console.log('FETCHING NOTIFICATIONS', prevProps.profile, this.props.profile)
  //     const count = await HTTP_NEW_NOTIFICATIONS(this.props.profile._id)
  //       .then((response) => response.json())
  //       .then((response) => {
  //         this.setState({
  //           items: response,
  //         });
  //       })
  //       .catch((error) => {
  //         // handle error
  //         console.log(error);
  //       });
  //   }
  //   if (this.props.number === null) {
  //     return false;
  //   } else if (prevProps.number !== this.props.number) {
  //     await HTTP_NEW_NOTIFICATIONS(this.props.profile._id)
  //       .then((response) => response.json())
  //       .then((response) => {
  //         this.setState({
  //           items: response,
  //         });
  //       })
  //       .catch((error) => {
  //         // handle error
  //         console.log(error);
  //       });
  //   }
  // }

  async componentDidUpdate(prevProps) {
    const {profile, number} = this.props;

    if (
      (profile !== null && prevProps.profile !== profile) ||
      (number !== null && prevProps.number !== number)
    ) {
      // console.log('FETCHING NOTIFICATIONS', prevProps.profile, profile);

      // try {
      //   const response = await HTTP_NEW_NOTIFICATIONS(profile._id);
      //   const data = await response.json();

      //   this.setState({
      //     items: data,
      //   });
      // } catch (error) {
      //   console.log(error);
      // }
      await HTTP_NEW_NOTIFICATIONS(profile._id)
        .then((response) => response.json())
        .then((data) => {
          if (data.length === 0) {
            this.setState({items: [], noactions: true});
          } else {
            this.setState({items: data});
          }
        })
        .catch((error) => {
          // handle error
          console.log(error);
        });
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  HandleCustomer(id, rowData) {
    const {navigate} = this.props;
    navigate({
      pathname: `/uk/${id}`,
      state: {lead: rowData},
    });
  }

  render() {
    const columns = [
      {
        name: '_id',
        options: {
          label: 'Name',
          filter: false,
          sort: false,
          display: 'excluded',
        },
      },
      {
        name: 'subject',
        options: {
          label: 'Subject',
          filter: true,
          sort: true,
          setCellHeaderProps: (value) => ({
            style: {
              display: 'none',
            },
          }),
          // customBodyRender: (value, meta) => {
          //   const {navigate, close} = this.props;
          //   return (
          //     <Button
          //       color="primary"
          //       style={{
          //         textTransform: 'capitalize',
          //         textAlign: 'left',
          //         minwidth: '130px !important',
          //         fontWeight: 'inherit',
          //         textShadow: 'inherit',
          //       }}
          //       onClick={() => {
          //         const payload = {
          //           _id: meta.rowData[0],
          //           read: true,
          //         };
          //         HTTP_UPDATE_NOTIFICATION(payload);
          //         close();
          //         navigate({
          //           pathname: `${meta.rowData[6]}`,
          //         });
          //       }}>
          //       {value}
          //     </Button>
          //   );
          // },
        },
      },
      {
        name: 'creator',
        options: {
          label: 'Creator',
          filter: false,
          sort: false,
          display: 'excluded',
        },
      },
      {
        name: 'owner',
        options: {
          label: 'Owner',
          filter: false,
          sort: false,
          display: 'excluded',
        },
      },
      {
        name: 'date',
        options: {
          label: 'Date',
          filter: false,
          sort: true,
          setCellHeaderProps: (value) => ({
            style: {
              display: 'none',
            },
          }),
          setCellProps: (value) => ({
            style: {
              textAlign: 'right',
            },
          }),
          customBodyRender: (value) => {
            const strvalue = JSON.stringify(value);
            if (strvalue !== undefined) {
              const date = strvalue.substring(1, 11);
              const time = strvalue.substring(12, 17);
              const justyear = strvalue.substring(1, 5);
              const currentyear = new Date().getFullYear();
              const cyear = JSON.stringify(currentyear);
              const dateyear = strvalue.substring(1, 11);
              const today = moment().format('YYYY-MM-DD');
              const yearformat = moment(date).format('YYYY-MM-DD');
              const formatdate = moment(date).format('DD MMM');
              if (dateyear === today) {
                return time;
              } else if (cyear !== justyear) {
                return yearformat;
              } else {
                return formatdate;
              }
            }
          },
        },
      },
      {
        name: 'read',
        options: {
          label: 'Read',
          filter: false,
          sort: false,
          display: 'excluded',
        },
      },
      {
        name: 'link',
        options: {
          label: 'Link',
          filter: false,
          sort: false,
          display: 'excluded',
        },
      },
    ];

    const options = {
      filter: false,
      filterType: 'dropdown',
      viewColumns: false,
      print: false,
      rowsPerPageOptions: [],
      textLabels: {
        body: {
          noMatch: 'You have no unread notifications',
        },
      },
      setRowProps: (row, dataIndex, rowIndex) => {
        if (row[5] === false) {
          return {
            style: {fontWeight: '700', cursor: 'pointer'},
          };
        } else {
          return {
            style: {cursor: 'pointer'},
          };
        }
      },
      onRowClick: async (rowData, rowState, meta) => {
        const payload = {
          _id: rowData[0],
          read: true,
        };
        await HTTP_UPDATE_NOTIFICATION(payload).catch((error) =>
          console.log('UPDATE NOTIFICATION ERROR', error)
        );
        await HTTP_NEW_NOTIFICATIONS(this.props.profile._id)
          .then((response) => response.json())
          .then((response) => {
            this.setState({
              items: response,
            });
          })
          .catch((error) => {
            // handle error
            console.log(error);
          });
        // this.props.SelectFlow(rowData);
        if (typeof window !== 'undefined') {
          window.open(rowData[6]);
        }
        await this.props.UpdateCount();
      },
      search: false,
      download: false,
      selectableRows: 'none',
      sortOrder: {
        name: 'date',
        direction: 'desc',
      },
    };
    return (
      <MUIDataTable
        title=""
        color="primary"
        data={this.state.items}
        columns={columns}
        elevation={0}
        options={options}
        responsive
      />
    );
  }
}

NotificationTable.defaultProps = {
  profile: PropTypes.any,
  number: PropTypes.any,
  navigate: PropTypes.any,
  close: PropTypes.any,
};

NotificationTable.propTypes = {
  profile: PropTypes.any,
  number: PropTypes.any,
  navigate: PropTypes.any,
  close: PropTypes.any,
};

export default NotificationTable;
