export default function Caps(sentence) {
  if (typeof sentence === 'undefined' || sentence === null || typeof sentence !== 'string') {
    return '';
  }
  const nochar = sentence.replaceAll('"', '');
  const words = nochar.split(' ');
  for (let i = 0; i < words.length; i++) {
    words[i] = words[i].charAt(0).toUpperCase() + words[i].substr(1).toLowerCase();
  }
  const joined = words.join(' ');
  return joined;
}
