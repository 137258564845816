import React from 'react';
import { hydrateRoot } from 'react-dom/client';
import './lang/i18n';
import './App.scss';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { BrowserRouter } from 'react-router-dom';
import { loadableReady } from '@loadable/component';
import { HelmetProvider } from 'react-helmet-async';
import chalk from 'chalk';
import App from './App';
import configureStore from './store';
import 'reactflow/dist/style.css';

let preloadedState = {};

if (typeof window !== 'undefined') {
  window.onunhandledrejection = function (event) {
    const stack = event.reason.stack || '';
    const match = stack.match(/at\s+(.*):(\d+):(\d+)/);
    const fileName = match ? match[1] : 'unknown file';
    const lineNumber = match ? match[2] : 'unknown line';
    const columnNumber = match ? match[3] : 'unknown column';
    console.log(
      chalk.red(
        `Unhandled Promise Rejection in ${fileName} at line ${lineNumber}, column ${columnNumber}:`
      ),
      event.reason
    );
  };
}

if (typeof window !== 'undefined') {
  preloadedState = window.__PRELOADED_STATE__;
  if (window.__COMPANY__) {
    preloadedState.company = window.__COMPANY__;
  }
}

// Configure the Redux store with preloaded state
const { store, persistor } = configureStore(preloadedState);

const rootElement = document.getElementById('root');

loadableReady(() => {
  hydrateRoot(
    rootElement,
    <HelmetProvider>
      <Provider store={store}>
        <BrowserRouter>
          <GoogleOAuthProvider clientId="325559216488-5lnklpprne590ki2brq1d1i96ek48kv8.apps.googleusercontent.com">
            {/* <PersistGate loading={null} persistor={persistor}> */}
              <App />
            {/* </PersistGate> */}
          </GoogleOAuthProvider>
        </BrowserRouter>
      </Provider>
    </HelmetProvider>
  );
});

// import React from 'react';
// // import ReactDOM from 'react-dom';
// import {hydrate} from 'react-dom';
// import './lang/i18n';
// import './App.scss';
// import {Provider} from 'react-redux';
// import {PersistGate} from 'redux-persist/integration/react';
// import {GoogleOAuthProvider} from '@react-oauth/google';
// import {BrowserRouter} from 'react-router-dom';
// // import {ServerStyleSheets} from '@material-ui/styles';
// import {loadableReady} from '@loadable/component';
// import {HelmetProvider} from 'react-helmet-async';
// import chalk from 'chalk';
// import App from './App';
// import configureStore from './store';


// let preloadedState = {};

// if (typeof window !== 'undefined') {
//   window.onunhandledrejection = function (event) {
//     const stack = event.reason.stack || '';
//     const match = stack.match(/at\s+(.*):(\d+):(\d+)/);
//     const fileName = match ? match[1] : 'unknown file';
//     const lineNumber = match ? match[2] : 'unknown line';
//     const columnNumber = match ? match[3] : 'unknown column';
//     console.log(
//       chalk.red(
//         `Unhandled Promise Rejection in ${fileName} at line ${lineNumber}, column ${columnNumber}:`
//       ),
//       event.reason
//     );
//   };
// }

// if (typeof window !== 'undefined') {
//   // console.log('STORE CONFIGURATION', window.__COMPANY__);
//   preloadedState = window.__PRELOADED_STATE__;
//   if (window.__COMPANY__) {
//     preloadedState.company = window.__COMPANY__;
//   }
// }

// // Include company data in the Redux state if needed

// const {store, persistor} = configureStore(preloadedState);

// const rootElement = document.getElementById('root');

// loadableReady(() => {
//   hydrate(
//     <HelmetProvider>
//       <Provider store={store}>
//         <BrowserRouter>
//           <GoogleOAuthProvider clientId="325559216488-5lnklpprne590ki2brq1d1i96ek48kv8.apps.googleusercontent.com">
//             <PersistGate loading={null} persistor={persistor}>
//               <App />
//             </PersistGate>
//           </GoogleOAuthProvider>
//         </BrowserRouter>
//       </Provider>
//     </HelmetProvider>,
//     rootElement
//   );
// });

// const rootElement = document.getElementById('root');
// const isServer = typeof window === 'undefined';

// ReactDOM.render(
//   <GoogleOAuthProvider clientId="325559216488-5lnklpprne590ki2brq1d1i96ek48kv8.apps.googleusercontent.com">
//     <Provider store={store}>
//       <PersistGate persistor={persistor}>
//         <App />
//       </PersistGate>
//     </Provider>
//   </GoogleOAuthProvider>,
//   rootElement
// );
// console.log('IS SERVER OR NOT', isServer);
// if (!isServer) {
//   hydrate(
//     <GoogleOAuthProvider clientId="325559216488-5lnklpprne590ki2brq1d1i96ek48kv8.apps.googleusercontent.com">
//       <Provider store={store}>
//         <PersistGate persistor={persistor}>
//           <App />
//         </PersistGate>
//       </Provider>
//     </GoogleOAuthProvider>,
//     document.getElementById('root')
//   );
// } else {
//   render(
//     <GoogleOAuthProvider clientId="325559216488-5lnklpprne590ki2brq1d1i96ek48kv8.apps.googleusercontent.com">
//       <Provider store={store}>
//         <PersistGate persistor={persistor}>
//           <App />
//         </PersistGate>
//       </Provider>
//     </GoogleOAuthProvider>,
//     document.getElementById('root')
//   );
// }

// if (typeof document !== 'undefined') {
// if (document.getElementById('root').hasChildNodes()) {
//   if (RENDER === 'SERVER') {
//     loadableReady(() => {
//       hydrate(
//         <HelmetProvider>
//           <Provider store={store}>
//             <BrowserRouter>
//               <GoogleOAuthProvider clientId="325559216488-5lnklpprne590ki2brq1d1i96ek48kv8.apps.googleusercontent.com">
//                 <PersistGate persistor={persistor}>
//                   <ServerStyleSheets>
//                     <App />
//                   </ServerStyleSheets>
//                 </PersistGate>
//               </GoogleOAuthProvider>
//             </BrowserRouter>
//           </Provider>
//         </HelmetProvider>,
//         document.getElementById('root')
//       );
//     });
//   } else {
//     hydrate(
//       <HelmetProvider>
//         <Provider store={store}>
//           <BrowserRouter>
//             <GoogleOAuthProvider clientId="325559216488-5lnklpprne590ki2brq1d1i96ek48kv8.apps.googleusercontent.com">
//               <PersistGate persistor={persistor}>
//                 <App />
//               </PersistGate>
//             </GoogleOAuthProvider>
//           </BrowserRouter>
//         </Provider>
//       </HelmetProvider>,
//       document.getElementById('root')
//     );
//   }
// } else {
//   hydrate(
//     <HelmetProvider>
//       <Provider store={store}>
//         <BrowserRouter>
//           <GoogleOAuthProvider clientId="325559216488-5lnklpprne590ki2brq1d1i96ek48kv8.apps.googleusercontent.com">
//             <PersistGate persistor={persistor}>
//               <App />
//             </PersistGate>
//           </GoogleOAuthProvider>
//         </BrowserRouter>
//       </Provider>
//     </HelmetProvider>,
//     document.getElementById('root')
//   );
// }
// }
