import {PROFILE_URL, PROFILE_UPDATE_URL, PROFILE_COMPLETE_URL} from 'app/constants/url';
import {customFetch} from 'app/utils/customFetch';

/**
 * Fetch the user's profile.
 *
 * @export
 * @function
 * @name HTTP_REQUEST_PROFILE
 * @param token
 * @returns {Promise<Response>}
 */
export async function HTTP_REQUEST_PROFILE(token) {
  try {
    // Parse the JSON only once here and return it
    // const jsonData = await response.json();
    const response = await customFetch(PROFILE_URL, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'X-API-KEY': process.env.FETCH_CONNECT,
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    console.error('Error occurred while parsing JSON:', error);
  }

  // // Check if the response is okay to parse
  // if (!response.ok) {
  //   console.error(`HTTP error: ${response.status}`);
  //   // You might want to handle different HTTP status codes here
  //   return null;
  // }

  // // Ensure the response is in JSON format
  // const contentType = response.headers.get('Content-Type');
  // if (!contentType || !contentType.includes('application/json')) {
  //   console.error('Response not in JSON format');
  //   return null;
  // }

  // try {
  //   // Parse the JSON only once here and return it
  //   const jsonData = await response.json();
  //   return jsonData;
  // } catch (error) {
  //   console.error('Error occurred while parsing JSON:', error);
  //   throw error;
  // }
}
// export async function HTTP_REQUEST_PROFILE(token) {
//   const response = await customFetch(PROFILE_URL, {
//     method: 'GET',
//     headers: {
//       Accept: 'application/json',
//       'X-API-KEY': process.env.FETCH_CONNECT,
//       Authorization: `Bearer ${token}`,
//     },
//   });

//   // if (!response.ok) {
//   //   console.error(`HTTP error: ${response.status}`);
//   //   return response;
//   // }

//   // Parse the JSON only once here and return it
//   try {
//     const jsonData = await response.json();
//     console.log('HTTP_REQUEST_PROFILE RESPONSE', jsonData);
//     return jsonData;
//   } catch (error) {
//     console.error('Error occurred while parsing JSON:', error);
//     throw error;
//   }
// }

/**
 * Update a Profile
 *
 * @export
 * @function
 * @name HTTP_COMPLETE_PROFILE
 * @param payload
 * @returns {Promise<Response>}
 */
export async function HTTP_COMPLETE_PROFILE(payload) {
  return await customFetch(PROFILE_COMPLETE_URL, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });
}

/**
 * Update a Profile
 *
 * @export
 * @function
 * @name HTTP_UPDATE_PROFILE
 * @param payload
 * @returns {Promise<Response>}
 */
export async function HTTP_UPDATE_PROFILE(payload) {
  return await customFetch(PROFILE_UPDATE_URL, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });
}
