import React, {Component} from 'react';
import PropTypes from 'prop-types';
import sweetAlert from 'sweetalert2';
import {connect} from 'react-redux';
import {withNamespaces} from 'react-i18next';
import {HTTP_REQUEST_EMAIL_CONFIRM} from 'app/services/httpClient/auth.service';
import {CONFIRM_ACTION} from 'app/actions/authAction';
import {PROFILE_ACTION} from 'app/actions/profileAction';
import Grid from '@mui/material/Grid';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {toast} from 'react-toastify';
import CssBaseline from '@mui/material/CssBaseline';
import Avatar from '@mui/material/Avatar';
import withRouter from 'app/utils/withRouter';

function mapStateToProps(state) {
  return {
    GET_AUTH_STATE: state.AUTH_REDUCER.Auth,
    GET_PROFILE_STATE: state.PROFILE_REDUCER.Profile,
    GET_ERRORS_STATE: state.ERROR_REDUCER.Errors,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    DISPATCH_PROFILE: (token) => dispatch(PROFILE_ACTION(token)),
    DISPATCH_CONFIRM: (payload) => dispatch(CONFIRM_ACTION(payload)),
  };
}

class Confirm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Payload: {
        token: null,
      },
      GET_ERRORS_STATE: [],
      confirmed: false,
      message: '',
    };
    // bind event handlers in class components.
    this.HandleSubmit = this.HandleSubmit.bind(this);
  }

  async componentDidMount() {
    // console.log('MOUNTED');
    const {navigate, GET_AUTH_STATE, DISPATCH_CONFIRM} = this.props;
    if (GET_AUTH_STATE.isAuthenticated) {
      this.setState({
        message: 'Email confirmed. Please set up your account.',
      });
    } else {
      this.setState({
        message: 'Email confirmed. Please sign into your account.',
      });
    }
    this.setState({
      Payload: {
        token: typeof window ? window.location.pathname.split('/')[2] : '',
      },
    });
    const confirmpayload = {
      token: typeof window ? window.location.pathname.split('/')[2] : '',
    };
    // const response = await HTTP_REQUEST_EMAIL_CONFIRM(confirmpayload);
    const response = await DISPATCH_CONFIRM(confirmpayload);
    // console.log('DISPLATCH RESPONSE', response);
    // console.log('CONRFIRM RESPONSE', response);
    // if (response.response.status === 'all okay') {
    //   // const {DISPATCH_PROFILE, GET_AUTH_STATE} = this.props;
    //   // await DISPATCH_PROFILE(GET_AUTH_STATE.token);
    //   toast(this.state.message, {
    //     onClose: () => navigate('/leads'),
    //     type: toast.TYPE.SUCCESS,
    //   });
    // } else {
    //   toast.error('There was an issue confirming your email.');
    // }
  }

  componentDidUpdate(prevState) {}

  async HandleSubmit(event) {
    event.preventDefault();
    const emailToken = this.state.Payload;
    const {navigate, GET_AUTH_STATE, DISPATCH_CONFIRM} = this.props;
    // const response = await HTTP_REQUEST_EMAIL_CONFIRM(emailToken);
    DISPATCH_CONFIRM(emailToken);
    // console.log('DISPLATCH RESPONSE 1', response);
    // if (response.response.status === 'all okay') {
    //   // const {DISPATCH_PROFILE, GET_AUTH_STATE} = this.props;
    //   // await DISPATCH_PROFILE(GET_AUTH_STATE.token);
    //   toast(this.state.message, {
    //     onClose: () => navigate('/leads'),
    //     type: toast.TYPE.SUCCESS,
    //   });
    // } else {
    //   toast.error('There was an issue confirming your email.');
    // }
    // if (response.status === 200) {
    //   await sweetAlert.fire('Good job!', 'Your email has been confirmed successfully.', 'success');
    //   await DISPATCH_PROFILE(GET_AUTH_STATE.token);
    //   this.setState({confirmed: true});
    // } else {
    //   const {errors} = await response.json();
    //   this.setState({
    //     GET_ERRORS_STATE: Array.isArray(errors) ? [...errors] : [errors],
    //   });
    // }
  }

  render() {
    return (
      <Container className="login-container" component="main" maxWidth="sm">
        <CssBaseline />
        <div className="paper">
          <Avatar className="avatar">
            <MailOutlineIcon />
          </Avatar>
          <Typography className="loginheader" component="h1" variant="h5">
            Confirming account...
          </Typography>
          <Grid container>
            <Grid item xs={12}>
              <Typography style={{paddingTop: '24px', paddingBottom: '24px'}}>
                This should only take a few seconds. If you're having issues, contact support.
              </Typography>
            </Grid>
          </Grid>
        </div>
      </Container>
    );
  }
}

Confirm.propTypes = {
  DISPATCH_PROFILE: PropTypes.any.isRequired,
  GET_AUTH_STATE: PropTypes.any.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Confirm));
