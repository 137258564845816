import {LEARN, ACTIVITY} from 'app/constants/actionTypes';

const initState = {
  Activity: {
    industrykey: null,
    value: null,
    text: null,
  },
};

const initLearnState = {
  isOpen: true,
  lead: false,
  leadsearch: false,
  leads: false,
  addlead: false,
  enrichlead: false,
};

/**
 * Activity Reducer.
 *
 * @function
 * @name ActivityReducer
 * @param state
 * @param type
 * @param payload
 * @returns {object}
 */
export function ActivityReducer(state = initState, {type, payload}) {
  switch (type) {
    case ACTIVITY:
      state = {
        Activity: {
          ...payload.response.data,
        },
      };
      break;
  }
  return state;
}
