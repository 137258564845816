/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import { Route, Navigate, Routes, Outlet, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { PROFILE_ACTION } from 'app/actions/profileAction';
import PropTypes from 'prop-types';

/**
 * Protect private routes for unauthorized access.
 *
 * @export
 * @function
 * @name LoginRedirect
 * @returns {Function}
 */
const LoginRedirect = function ({ children, page }) {
  const location = useLocation();
  // const navigate = useNavigate();

  return location.state ? (
    <Navigate to={location.state.lastpage} replace />
  ) : (
    <Navigate to="/companies" replace />
  );
  // if (auth) {
  //   return children;
  // } else {
  //   return <Navigate to="/login" />;
  // }
};
// }
// function PrivateRoute({component: Component, ...rest}) {
//   return (
//     <Routes>
//     <Route
//       {...rest}
//       render={props =>
//         Cookies.get('_token') !== null ? (
//           <Component {...props} />
//         ) : (
//           <Navigate to="/login" />
//         )
//       }
//     />
//     </Routes>
//   );
// }

// PrivateRoute.propTypes = {
//   component: PropTypes.any.isRequired,
//   history: PropTypes.object.isRequired,
// };

export default LoginRedirect;
