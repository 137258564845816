import {configureStore} from '@reduxjs/toolkit';
import {persistStore, persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import {createBrowserHistory, createMemoryHistory} from 'history';
import {rootReducer} from 'app/reducers/rootReducer';

export const history =
  typeof window !== 'undefined' ? createBrowserHistory() : createMemoryHistory();

const middleware = [thunk];

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['PROFILE_REDUCER', 'ERROR_REDUCER', 'BUSINESS_REDUCER', 'ACTIVITY_REDUCER'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default () => {
  const store = configureStore({
    reducer: persistedReducer,
    middleware,
    devTools: process.env.NODE_ENV !== 'production',
  });

  const persistor = persistStore(store);

  return {store, persistor};
};
