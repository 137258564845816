import {SETUP_BUSINESS} from 'app/constants/actionTypes';

const initState = {
  Business: {
    businessowner: null,
    businessname: null,
    buscountry: null,
    busregion: null,
    buscity: null,
    activity: null,
  },
};

/**
 * Business Reducer.
 *
 * @function
 * @name BusinessReducer
 * @param state
 * @param type
 * @param payload
 * @returns {object}
 */
export function BusinessReducer(state = initState, {type, payload}) {
  switch (type) {
    case SETUP_BUSINESS:
      state = {
        Profile: {
          ...payload.response.data,
        },
      };
      break;
  }
  return state;
}
