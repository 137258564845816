import React, { useEffect, useRef } from 'react';
import Grid from '@mui/material/Grid';
import Message from './Message';

function Messages({ messages, height }) {
  const messageListRef = useRef();

  // Scroll to the bottom whenever messages change
  useEffect(() => {
    const messageList = messageListRef.current;
    messageList.scrollTop = messageList.scrollHeight;
  }, [messages]);

  const messageComponents = messages.map((message, i) => (
    <Message
      key={i}
      username={message.username}
      message={message.message}
      fromMe={message.fromMe}
    />
  ));

  return (
    <Grid
      id="messageList"
      ref={messageListRef}
      style={{
        overflowY: 'scroll',
        overflowX: 'hidden',
        flexGrow: '1',
        padding: '20px',
        height,
        scrollbarColor: '#003671 #ffffff',
      }}
    >
      {messageComponents}
    </Grid>
  );
}

Messages.defaultProps = {
  messages: [],
  height: '',
};

export default Messages;
