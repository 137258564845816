import {
  GATHER_GETPROFILES_URL,
  GATHER_COUNTPROFILE_URL,
  GATHER_UKLOCATIONS_URL,
  GATHER_WORLDLOCATIONS_URL,
  ACTIVITY_URL,
  GATHER_UKBUSINESSES_URL,
  GATHER_ADDPROFILES_URL,
  GATHER_ADDINSIGHT_URL,
  GATHER_INSIGHTS_URL,
  GATHER_PROFILE_STATUS_URL,
  GATHER_GETLEADS_URL,
  GATHER_GETSOCIAL_URL,
  GATHER_GETLEAD_URL,
  GATHER_UPDATELEAD_URL,
  GATHER_COUNTNOTIFICATIONS_URL,
  GATHER_NOTIFICATIONS_URL,
  GATHER_NEWNOTIFICATIONS_URL,
  GATHER_ADDNOTIFICATION_URL,
  GATHER_UPDATENOTIFICATION_URL,
  GATHER_DELETENOTIFICATION_URL,
  GATHER_MARKREADNOTIFICATION_URL,
  GATHER_SUB_NOTIFICATIONS_URL,
  GATHER_SUBMITMESSAGE_URL,
  CITY_URL,
} from 'app/constants/url';
import {customFetch} from 'app/utils/customFetch';

/**
 * Submit Message
 *
 * @export
 * @function
 * @name HTTP_SUBMIT_MESSAGE
 * @param payload
 * @returns {Promise<Response>}
 */
export async function HTTP_SUBMIT_MESSAGE(payload) {
  const string = JSON.stringify(payload);
  return await customFetch(GATHER_SUBMITMESSAGE_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: string,
  });
}

/**
 * Fetch business activities.
 *
 * @export
 * @function
 * @name HTTP_REQUEST_ACTIVITY
 * @param payload
 * @returns {<Response>}
 */
export async function HTTP_REQUEST_ACTIVITY(payload) {
  return await customFetch(ACTIVITY_URL, {
    method: 'GET',
  });
}

/**
 * Get profiles.
 *
 * @export
 * @function
 * @name HTTP_REQUEST_PROFILES
 * @param payload
 * @returns {Promise<Response>}
 */
export async function HTTP_REQUEST_PROFILES(payload) {
  const url = `${GATHER_GETPROFILES_URL}/:${payload}`;
  return await customFetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

/**
 * Get profiles.
 *
 * @export
 * @function
 * @name HTTP_REQUEST_LEADS
 * @param payload
 * @returns {Promise<Response>}
 */
export async function HTTP_REQUEST_LEADS(payload) {
  const url = `${GATHER_GETLEADS_URL}/:${payload}`;
  return await customFetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

/**
 * Get profiles.
 *
 * @export
 * @function
 * @name HTTP_REQUEST_SOCIAL
 * @param payload
 * @returns {Promise<Response>}
 */
export async function HTTP_REQUEST_SOCIAL(payload) {
  const url = `${GATHER_GETSOCIAL_URL}/:${payload}`;
  return await customFetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

/**
 * Get profiles.
 *
 * @export
 * @function
 * @name HTTP_REQUEST_LEAD
 * @param payload
 * @returns {Promise<Response>}
 */
export async function HTTP_REQUEST_LEAD(payload) {
  const url = `${GATHER_GETLEAD_URL}/:${payload}`;
  return await customFetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

/**
 * Update a lead
 *
 * @export
 * @function
 * @name HTTP_UPDATE_LEAD
 * @param payload
 * @returns {Promise<Response>}
 */
export async function HTTP_UPDATE_LEAD(payload) {
  return await customFetch(GATHER_UPDATELEAD_URL, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });
}

/**
 * Add profiles.
 *
 * @export
 * @function
 * @name HTTP_ADD_PROFILES
 * @param payload
 * @returns {Promise<Response>}
 */
export async function HTTP_ADD_PROFILES(payload) {
  const string = JSON.stringify(payload);
  return await customFetch(GATHER_ADDPROFILES_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: string,
  });
}

/**
 * Change profile status.
 *
 * @export
 * @function
 * @name HTTP_PROFILE_STATUS
 * @param payload
 * @returns {Promise<Response>}
 */
export async function HTTP_PROFILE_STATUS(payload) {
  const string = JSON.stringify(payload);
  return await customFetch(GATHER_PROFILE_STATUS_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: string,
  });
}

/**
 * Count profile.
 *
 * @export
 * @function
 * @name HTTP_COUNT_PROFILE
 * @param payload
 * @returns {Promise<Response>}
 */
export async function HTTP_COUNT_PROFILE(payload) {
  const string = JSON.stringify(payload);
  // const url = `${GATHER_ADDPROFILES_URL}/:${payload}`;
  return await customFetch(GATHER_COUNTPROFILE_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: string,
  });
}

/**
 * Add insight.
 *
 * @export
 * @function
 * @name HTTP_ADD_INSIGHT
 * @param payload
 * @returns {Promise<Response>}
 */
export async function HTTP_ADD_INSIGHT(payload) {
  const string = JSON.stringify(payload);
  return await customFetch(GATHER_ADDINSIGHT_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: string,
  });
}

/**
 * Fetch business activities.
 *
 * @export
 * @function
 * @name HTTP_REQUEST_INSIGHTS
 * @param payload
 * @returns {<Response>}
 */
export async function HTTP_REQUEST_INSIGHTS(payload) {
  const url = `${GATHER_INSIGHTS_URL}/:${payload}`;
  return await customFetch(url, {
    method: 'GET',
  });
}

/**
 * Fetch region activities.
 *
 * @export
 * @function
 * @name HTTP_REQUEST_UKLOCATIONS
 * @param payload
 * @returns {Promise<Response>}
 */
export async function HTTP_REQUEST_UKLOCATIONS(payload) {
  // console.log('REQUEST UK PLACES', payload);
  return await customFetch(GATHER_UKLOCATIONS_URL, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });
}

/**
 * Request notifications.
 *
 * @export
 * @function
 * @name HTTP_REQUEST_WORLDLOCATIONS
 * @param payload
 * @returns {Promise<Response>}
 */
export async function HTTP_REQUEST_WORLDLOCATIONS(payload) {
  return await customFetch(GATHER_WORLDLOCATIONS_URL, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });
}

/**
 * Fetch region activities.
 *
 * @export
 * @function
 * @name HTTP_REQUEST_UKBUSINESSES
 * @param payload
 * @returns {<Response>}
 */
export async function HTTP_REQUEST_UKBUSINESSES(payload) {
  return await customFetch(GATHER_UKBUSINESSES_URL, {
    method: 'GET',
  });
}

/**
 * Fetch business activities.
 *
 * @export
 * @function
 * @name HTTP_REQUEST_CITY
 * @param payload
 * @returns {<Response>}
 */
export async function HTTP_REQUEST_CITY(payload) {
  return await customFetch(CITY_URL, {
    method: 'GET',
  });
}

/**
 * Request notifications.
 *
 * @export
 * @function
 * @name HTTP_REQUEST_NOTIFICATIONS
 * @param payload
 * @returns {Promise<Response>}
 */
export async function HTTP_REQUEST_NOTIFICATIONS(payload) {
  const string = JSON.stringify(payload);
  const url = `${GATHER_NOTIFICATIONS_URL}/:${payload}`;
  return await customFetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

/**
 * Request sub notifications.
 *
 * @export
 * @function
 * @name HTTP_REQUEST_SUB_NOTIFICATIONS
 * @param payload
 * @returns {Promise<Response>}
 */
export async function HTTP_REQUEST_SUB_NOTIFICATIONS(payload) {
  const url = `${GATHER_SUB_NOTIFICATIONS_URL}/${payload}`;
  return await customFetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

/**
 * Request notifications.
 *
 * @export
 * @function
 * @name HTTP_NEW_NOTIFICATIONS
 * @param payload
 * @returns {Promise<Response>}
 */
export async function HTTP_NEW_NOTIFICATIONS(payload) {
  const url = `${GATHER_NEWNOTIFICATIONS_URL}/:${payload}`;
  return await customFetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

/**
 * Request notifications.
 *
 * @export
 * @function
 * @name HTTP_ADD_NOTIFICATIONS
 * @param payload
 * @returns {Promise<Response>}
 */
export async function HTTP_ADD_NOTIFICATIONS(payload) {
  return await customFetch(GATHER_ADDNOTIFICATION_URL, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });
}

/**
 * Update a Notification
 *
 * @export
 * @function
 * @name HTTP_UPDATE_NOTIFICATION
 * @param payload
 * @returns {Promise<Response>}
 */
export async function HTTP_UPDATE_NOTIFICATION(payload) {
  // console.log('GATHER SERVICE NOTIF PLOAD', payload);
  return await customFetch(GATHER_UPDATENOTIFICATION_URL, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });
}

/**
 * Delete a Notification
 *
 * @export
 * @function
 * @name HTTP_DELETE_NOTIFICATION
 * @param payload
 * @returns {Promise<Response>}
 */
export async function HTTP_DELETE_NOTIFICATION(payload) {
  // console.log('GATHER SERVICE NOTIF PLOAD', payload);
  return await customFetch(GATHER_DELETENOTIFICATION_URL, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });
}

/**
 * Update a Notification
 *
 * @export
 * @function
 * @name HTTP_MARKREAD_NOTIFICATION
 * @param payload
 * @returns {Promise<Response>}
 */
export async function HTTP_MARKREAD_NOTIFICATION(payload) {
  return await customFetch(GATHER_MARKREADNOTIFICATION_URL, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });
}

/**
 * Count notifications.
 *
 * @export
 * @function
 * @name HTTP_COUNT_NOTIFICATIONS
 * @param payload
 * @returns {Promise<Response>}
 */
export async function HTTP_COUNT_NOTIFICATIONS(payload) {
  const url = `${GATHER_COUNTNOTIFICATIONS_URL}/:${payload}`;
  return await customFetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  });
}
